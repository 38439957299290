.reports-grid-container {
  .reports-cards-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
    grid-gap: 1rem;

    .report-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .report-card-header-text {
      flex: 0 0 87%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.reports-table-title {
  font-size: 0.65rem;
  letter-spacing: 1px;
}

.reports-table-values {
  font-size: 0.8125rem;
}

.report_card_button_disabled {
  background-color: $lavender-mist;
  border-color: $lavender-mist;
  color: $dusty-violet;

  &:hover {
    background-color: $lavender-mist;
    border-color: $lavender-mist;
    color: $dusty-violet;
    transform: translateY(-1px);
  }
}
