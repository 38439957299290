.account_card {
  flex-grow: 1;
}


.account_card_button_disabled {
  background-color: $lavender-mist;
  border-color: $lavender-mist;
  color: $dusty-violet;

  &:hover {
    background-color: $lavender-mist;
    border-color: $lavender-mist;
    color: $dusty-violet;
    transform: translateY(-1px);
  }
}

.account_card_disabled.pt-3.pb-1.ps-4.card-header h3 {
  color: $dusty-violet;
}

.account_platform_item {
  &:hover {
    outline: 2px $cyan-100 solid;
  }

  &.selected {
    outline: 2px $primary solid;
  }
}
