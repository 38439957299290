@import 'editable_input';
@import 'font';
@import 'view_header';
@import 'table';
@import 'badge';
@import 'flex_basis';

.cursor-pointer {
  cursor: pointer;
}

.reports-global-container {
  display: flex;

  .main-content {
    flex-grow: 1;
  }
}

.date_selector {
  z-index: 200;
  position: relative;
}

.date_selector_box {
  position: absolute;
  right: 0;
  box-shadow: rgba(0, 0, 0, 0.24) -3px 3px 21px;
}
